<template>
	<div class="verification">
		<section class="request" v-if="artistVerificationRequest">
			<header class="request-header">
				<div class="informations">
					<div class="left_info">
						<div class="owner" v-if="artistVerificationRequest.owner">
							<v-avatar :width="50" :height="50" :min-width="50">
								<v-img
									lazy-src="https://via.placeholder.com/40"
									:src="ownerAvatar"
									:alt="artistVerificationRequest.owner.nickname"
								></v-img>
							</v-avatar>
							<div class="owner_data">
								<div class="user" @click.stop="redirectToUserProfile(artistVerificationRequest.owner.id, artistVerificationRequest.owner.nickname)">
									<span>User:</span>
									<div class="link">{{ artistVerificationRequest.owner.nickname }}</div>
								</div>
								<div class="email">
									<span>Email:</span> {{ artistVerificationRequest.email }}
								</div>
							</div>
						</div>
					</div>

					<div class="requests">
						<!-- Requests: <span>2</span>-->
					</div>
					<div class="creation-date">
						Received: {{ artistVerificationRequest.createdAt | moment("MMM DD, YYYY") }}
					</div>
				</div>
				<v-menu
					transition="slide-y-transition"
					:close-on-content-click="true"
					:nudge-width="230"
					offset-y
					nudge-left="220"
					nudge-bottom="10"
					origin="top center"
					content-class="menu_admin">
					<v-list class="admin_menu">
						<v-list-item :class="{disable: artistVerificationRequest.status === 'pending'}" @click="mark('pending')" v-ripple>
							Mark Pending
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'approved'}" @click="mark('approved')"  v-ripple>
							Approve
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'denied'}"  @click="mark('denied')" v-ripple>
							Deny
						</v-list-item>
						<v-list-item :class="{disable: artistVerificationRequest.status === 'banned'}"  @click="mark('banned')" v-ripple>
							Ban user
						</v-list-item>
					</v-list>
					<template v-slot:activator="{ on, attrs }">
						<div class="actions-menu" v-bind="attrs" v-on="on" v-ripple></div>
					</template>
				</v-menu>
			</header>
			<div class="request-body">
				<h4>Account Requested</h4>
				<div class="artist" v-if="artistVerificationRequest.artist">
					<v-avatar :width="50" :height="50" :min-width="50">
						<v-img
							lazy-src="https://via.placeholder.com/40"
							:src="artistAvatar"
							:class="{'default': !ARTIST_VERIFICATION_REQUEST.artist.avatar }"
							:alt="artistVerificationRequest.artist.name"
						></v-img>
					</v-avatar>
					<div class="link" @click="redirectToArtistPage(artistVerificationRequest.artist.id, artistVerificationRequest.artist.identifier)">
                        {{ artistVerificationRequest.artist.name }}
                    </div>
				</div>
				<h4>Uploaded Documents</h4>

				<ul class="documents-list">
					<li>
						<preview-image
							:width="135"
							:height="135"
							:src="getImageUrl(artistVerificationRequest.idCardPhoto)"
						></preview-image>
					</li>

					<li>
						<preview-image
							:width="135"
							:height="135"
							:src="getImageUrl(artistVerificationRequest.selfPhoto)"
						></preview-image>
					</li>
				</ul>

				<h4>Links</h4>
				<ul v-if="links.length > 0" class="links-list">
					<li v-for="link in links" v-bind:key="link">
						<div class="link" @click="redirectToLink(link)">{{ link }}</div>
					</li>
				</ul>
				<h4>User Comments</h4>
				<p class="user-comments" v-html="nl2br(artistVerificationRequest.comments)">
				</p>
			</div>
		</section>
		<verification-comments v-if="artistVerificationRequest" :verification-request="artistVerificationRequest"></verification-comments>
		<div class="page-loader">
			<v-progress-circular v-if="loader" :size="32" :width="3" color="#20C4F5" indeterminate></v-progress-circular>
		</div>
	</div>
</template>

<script>
import verificationComments from "@/components/manage/verificationComments";
import {mapActions, mapGetters} from "vuex";
import PreviewImage from "@/components/manage/previewImage";
import ModalImage from "@/components/public/modalImage";

export default {
	name: 'verification',
	props: ['id'],
	components: {
		PreviewImage,
		verificationComments,
		ModalImage
	},
	data() {
		return {
			loader: true,
			artistVerificationRequest: null,
			status: null,
			stats: {},
			comments: '',
			apiUrl: process.env.VUE_APP_API_URL
		}
	},
	created() {
		this.GET_ARTIST_VERIFICATION_REQUEST(this.id)
			.then(() => {
				this.artistVerificationRequest = this.ARTIST_VERIFICATION_REQUEST;
			})
			.catch(err => {
				console.log(`GET_ARTIST_VERIFICATION_REQUEST, ${err}`);
			})
			.finally(() => {
				this.loader = false;
			});
	},
	methods: {
		...mapActions([
			'GET_ARTIST_VERIFICATION_REQUEST',
			'PATCH_ARTIST_VERIFICATION_REQUEST',
		]),
		redirectToArtistPage(artistId, artistName) {
			// const name = artistName.trim().replace(/ /g, '-');
			const routeData = this.$router.resolve({name: 'artist-profile', params: {id: artistId, name: window.toHumanFormat(artistName)}});
			window.open(routeData.href, '_blank');
		},
		redirectToUserProfile(userID, userName) {
			const routeData = this.$router.resolve({name: 'user-profile', params: {id: userID, name: window.toHumanFormat(userName)}});
			window.open(routeData.href, '_blank');
		},
		redirectToLink(link) {
			// window.location.href = this.CREATE_LINK_STRIPE_ACCOUNT;
			let formattedLink = link;
			if (!link.includes('http://') && !link.includes('https://')) {
				formattedLink = 'https://' + link;
			}
			window.open(formattedLink, '_blank');
		},
		getImageUrl(mediaObject) {
			if (!mediaObject) {
				return '';
			}

			return this.apiUrl + mediaObject.contentUrl
		},
		mark(status) {
			console.log('status', status);
			if (this.artistVerificationRequest.status === status) {
				return;
			}
			this.loader = true;
			this.PATCH_ARTIST_VERIFICATION_REQUEST({id: this.id, status: status})
				.then(() => {
					let index = this.ARTIST_VERIFICATION_REQUESTS.findIndex((request) => +request.id === this.id);
					if (index !== -1) {
						this.ARTIST_VERIFICATION_REQUESTS.splice(index, 1);
					}

					this.artistVerificationRequest.status = status;
					// trigger event

					this.$emit('verification_status_updated', this.artistVerificationRequest);
				})
				.catch(err => {
					console.error('PATCH_ARTIST_VERIFICATION_REQUEST', err);
				})
				.finally(() => {
					this.loader = false;
				});
		},
		nl2br(str) {
			if (typeof str === 'undefined' || str === null) {
				return '';
			}

			return str.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');
		}
	},
	computed: {
		...mapGetters(['ARTIST_VERIFICATION_REQUEST', 'ARTIST_VERIFICATION_REQUESTS']),
		artistAvatar() {
			if (!this.ARTIST_VERIFICATION_REQUEST.artist.avatar) {
				return require('@/assets/image/svg/square_avatar.svg');
			}

			return this.getImageUrl(this.ARTIST_VERIFICATION_REQUEST.artist.avatar);
		},
		ownerAvatar() {
			if (!this.ARTIST_VERIFICATION_REQUEST.owner.photo) {
				return require('@/assets/image/svg/square_avatar.svg');
			}

			return this.getImageUrl(this.ARTIST_VERIFICATION_REQUEST.owner.photo);
		},
		links() {
			return this.nl2br(this.artistVerificationRequest.links).split('<br>');
		},
	}
}
</script>

<style lang="scss">
.verification {
	position: relative;

	.page-loader .v-progress-circular {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%), translateY(-50%);
		width: 32px;
		height: 32px;
	}

	section.request {
		border-radius: 8px;
		padding: 15px 0;
		box-sizing: border-box;
		margin-bottom: 4px;

		header.request-header {
			display: flex;
			align-items: center;
			//padding: 8px 8px 10px 8px;
			//border-bottom: 1px solid #7481A6;
			padding: 0 20px;
			background-color: #1B1F34;
			height: auto;

			.informations {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1;
				color: #7481A6;
				font-size: 16px;

				.owner {
					display: flex;
					.owner_data {
						> div {
							color: #20C4F5;
							> span {
								color: #f1f1f1;
							}
							&.user {
								cursor: pointer;
								display: inline-flex;
								> .link {
									padding-left: 7px;
									&:hover {
										text-decoration: underline;
										//border-bottom: 1px solid #20C4F5;
									}
								}

							}
						}
					}
				}

				li {
					margin-right: 16px;

					&.email {
						span {
							color: #20C4F5;
						}
					}

					&.requests {
						flex: 1;

						span {
							color: #E94E4E;
						}
					}

					&.creation-date {
						color: #DCDCDC;
						font-size: 14px;
					}
				}

			}

			.actions-menu {
				width: 34px;
				height: 34px;
				background: url(../../assets/img/more-menu-btn.svg) no-repeat center center;
				margin-left: 15px;
				overflow: hidden;
				border-radius: 50%;
			}
		}

		.request-body {
			padding: 16px 20px;
			margin-top: 60px;
			.v-avatar {
				.v-image {
					.v-image__image {
						background-color: #fff;
						background-size: cover;
					}
					&.default {
						.v-image__image {
							background-size: 67%;
						}
					}
				}
				//.v-image__image {
				//	background-color: #fff;
				//	background-size: cover;
				//	&.default {
				//		border: 1px solid red;
				//		background-color: #fff;
				//
				//	}
				//}


			}
			.artist {
				display: flex;
				align-items: center;
				.link {
					color: #20C4F5;
					padding-left: 7px;
					font-size: 16px;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
			}

			h4 {
				font-size: 16px;
				color: #7481A6;
				margin-bottom: 8px;
				border-bottom: 1px solid rgba(#7481A6, .3);
			}

			h4:not(:first-child) {
				margin-top: 32px;
			}

			ul.documents-list {
				display: flex;
				list-style: none;
				margin-left: 0;
				margin-top: 0;

				li {
					margin-right: 18px;
					> img {
						width: 135px;
						height: 135px;
					}
				}
			}

			.links-list {
				div {
					font-family: "Montserrat", sans-serif;
					color: #20C4F5;
					cursor: pointer;
					&:hover {
						text-decoration: underline;
					}
				}
			}

			.user-comments {
				color: #F6F6F6;
				font-family: "Montserrat", sans-serif;
			}
		}
	}

	.v-avatar {
		margin-right: 8px;

		& + a {
			color: #20C4F5;
		}
	}

	.v-input {
		fieldset {
			width: 100%;
			background-color: #040A18;
			border-radius: 5px;
			border: 1px solid #282C47;
		}

		&:focus,
		&:hover {
			fieldset {
				border-color: #5F6986;
			}
		}

		input,
		textarea {
			font-size: 14px;
			line-height: 16px;
			color: white;
			caret-color: white;
		}

		textarea {
			outline: none;
			height: 104px;
		}
	}
}
</style>
